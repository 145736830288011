import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classes from './classes.module.scss';
import { PromotionContent, PromotionDefaultProps } from '../../prop_types';
import AppLink from '../../../routes/app_link';
import ExpandedText from '../../../../../common/helpers/expanded_text';
import { isHomePage } from '../../../../config/util';

const shareBannerIMG = require('../../img/share_banner.svg');
const closeIcon = require('../../img/close_banner.svg');

const countDownTitle = (countdownBeforeEndInSeconds, countdownBeforeEventInSeconds) => {
  if (countdownBeforeEndInSeconds) return 'ENDS IN:';
  if (countdownBeforeEventInSeconds) return 'STARTS IN:';
  return null;
};

const promotionActive = (
  countdownBeforeEndInSeconds,
  countdownBeforeEventInSeconds
) => !!countdownBeforeEndInSeconds || !!countdownBeforeEventInSeconds;

const shareIcon = (shareBanner) => {
  if (!shareBanner) return null;
  return (
    <AppLink
      to="/free_readings"
      type="button"
      className={ classes.shareIconContainer }
      key="invite"
    >
      <img className={ classes.shareIconImage } alt="ivite" src={ shareBannerIMG } />
    </AppLink>
  );
};

const closeBannerIcon = (updatePromoBannerVisibility) => {
  if (isHomePage()) return null;
  return (
    <button type="button" className={ classes.closeIconContainer } onClick={ updatePromoBannerVisibility }>
      <img className={ classes.closeIcon } alt="close" src={ closeIcon } />
    </button>
  );
};

const renderContdownContainer = (promotionIsActive, contdownContainer) => {
  if (!promotionIsActive) return null;
  return contdownContainer();
};

const renderPromoLabel = (promoBannerVisible, promoLabel, webBannerImgSrc) => {
  if (!webBannerImgSrc) return null;
  if (!promoBannerVisible && !isHomePage()) return null;
  return promoLabel();
};

function PromotionLabel({
  promotionSaleTitle, countdownBeforeEndInSeconds, shareBanner, loadHomePage,
  countdownBeforeEventInSeconds, promotionShortDescription, webBannerImgSrc,
  updatePromoBannerVisibility, promoBannerVisible
}) {
  let timer = null;
  let seconds = countdownBeforeEndInSeconds || countdownBeforeEventInSeconds;
  const [currentSeconds, setCurrentSeconds] = useState(seconds);
  const promotionIsActive = promotionActive(
    countdownBeforeEndInSeconds,
    countdownBeforeEventInSeconds
  );
  const startTimer = () => {
    timer = setInterval(() => {
      if (seconds <= 1) {
        loadHomePage(true);
        clearInterval(timer);
        timer = null;
      }
      seconds -= 1;
      setCurrentSeconds(seconds);
    }, 1000);
  };

  useEffect(() => {
    if (!timer && seconds > 0) {
      startTimer();
    }
    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, []);

  const contdownContainer = () => (
    <div className={ classes.countdownContainer }>
      <div className={ classes.countdownTitle }>
        {countDownTitle(countdownBeforeEndInSeconds, countdownBeforeEventInSeconds)}
      </div>
      <div className={ classes.countDownTimer }>
        <div className={ classes.countdown }>
          {Math.floor(currentSeconds / 3600).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> HOURS </div>
        </div>
        <div className={ classes.countdown }>
          {Math.floor((currentSeconds % 3600) / 60).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> MIN </div>
        </div>
        <div className={ classes.countdown }>
          {Math.floor((currentSeconds % 60) % 60).toLocaleString('en-US', { minimumIntegerDigits:2 })}
          <div className={ classes.countdownSubtitle }> SEC </div>
        </div>
      </div>
    </div>
  );

  const promoLabel = () => (
    <div className={ classes.container }>
      <img className={ classes.bgImage } src={ webBannerImgSrc } alt="" />
      <div className={ classes.mainContent }>
        <div className={ classes.promoContent }>
          <div className={ classes.textContainer }>
            <ExpandedText
              className={ classes.title }
              ellipsis
              customClasses={ { text: classes.title } }
            >
              {promotionSaleTitle}
            </ExpandedText>
            <ExpandedText
              className={ classes.title }
              ellipsis
              customClasses={ { text: classes.subtitle } }
            >
              <span className={ classes.subtitle }>
                {promotionShortDescription}
              </span>
            </ExpandedText>
          </div>
          {renderContdownContainer(promotionIsActive, contdownContainer)}
          {shareIcon(shareBanner)}
        </div>
        {closeBannerIcon(updatePromoBannerVisibility)}
      </div>
    </div>
  );

  return renderPromoLabel(promoBannerVisible, promoLabel, webBannerImgSrc);
}
PromotionLabel.propTypes = {
  ...PromotionContent,
  loadHomePage: PropTypes.func.isRequired,
  updatePromoBannerVisibility: PropTypes.func.isRequired,
  promoBannerVisible: PropTypes.bool.isRequired
};

PromotionLabel.defaultProps = {
  ...PromotionDefaultProps
};

export default PromotionLabel;
